import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "../../styles/hero-slider.css";

const HeroSlider = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const settings = {
    fade: true,
    speed: 2500,
    autoplaySpeed: 3000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://vdrive.in/index.php/slider', {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer mysecuretoken',
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setImages(data);
      } catch (error) {
        setError('Error fetching images');
        console.error('Fetch images error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Slider {...settings} className="hero__slider">
      {images.map((image, index) => (
        <div key={index} className="slider__item mt0">
          <img src={`https://vdrive.in/` +image.image} alt={`slider-${index}`} className="image" />
        </div>
      ))}
    </Slider>
  );
};

export default HeroSlider;
