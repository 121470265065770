import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AddCarForm from './AddForm';
import EditCarForm from './EditForm';
import '../styles/AdminPanel.css';

const AdminPanel = () => {
  const [cars, setCars] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [carsResponse, imagesResponse] = await Promise.all([
        fetch('https://vdrive.in/index.php/cars', {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer mysecuretoken',
            'Content-Type': 'application/json'
          }
        }),
        fetch('https://vdrive.in/index.php/slider', {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer mysecuretoken',
            'Content-Type': 'application/json'
          }
        })
      ]);

      if (!carsResponse.ok || !imagesResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const carsData = await carsResponse.json();
      const imagesData = await imagesResponse.json();

      setCars(carsData);
      setImages(imagesData);
    } catch (error) {
      setError('Error fetching data');
      console.error('Fetch data error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddCar = async (postData) => {
    setLoading(true);
    try {
      const response = await fetch('https://vdrive.in/index.php/cars', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer mysecuretoken',
        },
        body: postData,
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const newCar = await response.json();
      setCars([...cars, newCar]);
      setSnackbarMessage('Car added successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchData(); // Refresh car list and images
    } catch (error) {
      setSnackbarMessage('Error adding car');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Add car error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCar = async ({ id, formData }) => {
    setLoading(true);
    try {
      const response = await fetch(`https://vdrive.in/index.php/cars/${id}`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': 'Bearer mysecuretoken',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const updatedCar = await response.json();
      const updatedCars = cars.map(car => (car.id === updatedCar.id ? updatedCar : car));
      setCars(updatedCars);
      setSnackbarMessage('Car updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Error updating car');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Update car error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCar = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`https://vdrive.in/index.php/cars/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer mysecuretoken',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setCars(cars.filter(car => car.id !== id));
      setSnackbarMessage('Car deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Error deleting car');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Delete car error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    if (!imageFile) return;
    
    const formData = new FormData();
    formData.append('image', imageFile);

    setLoading(true);
    try {
      const response = await fetch('https://vdrive.in/index.php/slider', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer mysecuretoken',
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const newImage = await response.json();
      setImages([...images, newImage]);
      setSnackbarMessage('Image uploaded successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Error uploading image');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Image upload error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteImage = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`https://vdrive.in/index.php/slider/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer mysecuretoken',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setImages(images.filter(image => image.id !== id));
      setSnackbarMessage('Image deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Error deleting image');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Delete image error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const renderCars = () => {
    if (loading) return (
      <TableRow>
        <TableCell colSpan={6} align="center">
          <CircularProgress className="admin-spinner" />
        </TableCell>
      </TableRow>
    );
    if (error) return (
      <TableRow>
        <TableCell colSpan={6} align="center" className="admin-error">
          {error}
        </TableCell>
      </TableRow>
    );
    return (
      cars.map(car => (
        <TableRow key={car.id} className="admin-table-row">
          <TableCell className="admin-table-cell">
            <EditCarForm car={car} onUpdateCar={handleUpdateCar} handleDeleteCar={handleDeleteCar} />
          </TableCell>
        </TableRow>
      ))
    );
  };

  const renderImages = () => {
    if (loading) return (
      <TableRow>
        <TableCell colSpan={2} align="center">
          <CircularProgress className="admin-spinner" />
        </TableCell>
      </TableRow>
    );
    if (images.length === 0) return (
      <TableRow>
        <TableCell colSpan={2} align="center">
          No images uploaded.
        </TableCell>
      </TableRow>
    );
    return (
      images.map(image => (
        <TableRow key={image.id} className="admin-table-row">
          <TableCell className="admin-table-cell">
            <img src={`https://vdrive.in/` + image.image} alt={image.id} className="admin-image-preview" />
          </TableCell>
          <TableCell className="admin-table-cell">
            <Button variant="contained" color="primary" className="admin-delete-button" onClick={() => handleDeleteImage(image.id)}>Delete</Button>
          </TableCell>
        </TableRow>
      ))
    );
  };

  return (
    <div className="admin-panel">
      <h2>Add new Car</h2>
      <AddCarForm onAddCar={handleAddCar} />
      <h2>Cars List</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
             
            </TableRow>
          </TableHead>
          <TableBody>
            {renderCars()}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="admin-image-upload">
      <h2>Slider Images Upload</h2>
      <form className="image-upload-form" onSubmit={handleImageUpload}>
      <input
        type="file"
        className="image-upload-input"
        onChange={e => setImageFile(e.target.files[0])}
      />
      <button
        type="submit"
        className="image-upload-button"
      >
        Upload Image
      </button>
    </form>
      </div>
      <h2>Slider Images</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderImages()}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default AdminPanel;
