import React from 'react';
import HeroSlider from '../../components/UI/HeroSlider';
import Helmet from '../../components/Helmet/Helmet';
import Header from '../../components/Header/Header';
import AboutTemplate from '../../components/UI/AboutTemplate';



const Content = {
    title: `Self Drive Cars In Nagercoil | Self Drive Cars Nagercoil | Rent A Car In Nagercoil | Rent A Car Nagercoil | Self
    Drive Cars In Kanyakumari | Self Drive Cars Kanyakumari | Rent A Car In Kanyakumari | Rent A Car Kanyakumari | Best
    Self Drive Cars Rent In Nagercoil`,
    desc: `Kanyakumari, a breathtaking destination known for its natural beauty, historical landmarks, and cultural richness, offers visitors an unforgettable experience. From the iconic sunrise and sunset views to the confluence of three seas, the city is filled with mesmerizing attractions like temples, scenic beaches, and serene hill stations. Whether you're seeking relaxation or adventure, Kanyakumari is the perfect getaway. 

For those who wish to explore the hidden gems of Kanyakumari, self-drive cars provide the ultimate convenience. Unlike local transport, which can be unreliable, renting a car gives you the freedom to explore the region at your own pace, ensuring a stress-free and personalized journey. Whether you're visiting Kanyakumari for a weekend trip or an extended holiday, having your own self-drive car is the best way to maximize your experience.  `,
    services: [
        "Car rentals in Kanyakumari",
        "Self drive car rental in Kanyakumari",
        "Best self drive car rentals in Kanyakumari",
        "Self drive car in Kanyakumari",
        "Car hire in Kanyakumari",
        "Rental car services in Kanyakumari",
        "Rental self drive cars in Kanyakumari",
        "Car rental service in Kanyakumari",
        "Best car rental in Kanyakumari",
        "Self drive cars Kanyakumari",
        "Self Drive Cars In Kanyakumari",
        "Self Drive Cars Nagercoil",
        "Self Drive Cars in Nagercoil",
        "Rent a Car Kanyakumari",
        "Rent A Car In Kanyakumari",
        "Rent A Car Nagercoil",
        "Rent a Car In Nagercoil"
    ]
};



const RentKanyaKumari = () => {

    return (
        <Helmet title={Content.title} id="home">
            <Header />
            {/* ============= hero section =========== */}
            <section className="p-0 hero__slider-section">
                <HeroSlider />
            </section>
            {/* =========== about section ================ */}
            <section id="about">
                <AboutTemplate content={Content} />
            </section>
        </Helmet>
    )
}
export default RentKanyaKumari;