import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Routers from "../../routers/Routers";

 // List of all paths where you want the footer to show
 const footerVisiblePaths = [
  '/', 
  '/contact', 
  '/admin', 
  '/adminlogin', 
  '/self-drive-cars-nagercoil', 
  '/self-drive-cars-in-nagercoil', 
  '/rent-a-car-nagercoil', 
  '/rent-a-car-in-nagercoil', 
  '/self-drive-cars-marthandam', 
  '/self-drive-cars-in-marthandam', 
  '/rent-a-car-marthandam', 
  '/rent-a-car-in-marthandam', 
  '/self-drive-cars-kanyakumari', 
  '/self-drive-cars-in-kanyakumari', 
  '/rent-a-car-kanyakumari', 
  '/rent-a-car-in-kanyakumari'
];

const Layout = () => {
  const location = useLocation();
  const isFooterVisible = footerVisiblePaths.includes(location.pathname);
  return (
    <Fragment>
      {/* <Header /> */}
      <div>
        <Routers />
      </div>
      {isFooterVisible && <Footer />}
    </Fragment>
  );
};

export default Layout;
