import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div style={{ textAlign: "center", padding: "50px", fontFamily: "Arial, sans-serif" }}>
      <h1 style={{ fontSize: "3rem", color: "#f44336" }}>404 - Page Not Found</h1>
      <p style={{ fontSize: "1.25rem" }}>
        Oops! The page you're looking for doesn't exist.
      </p>
      <Link to="/" style={{ fontSize: "1.2rem", color: "#2196f3", textDecoration: "underline", background:"red", padding:"5px" }}>
        Go back to Home
      </Link>
    </div>
  );
};

export default NotFound;
